import React, { Component } from 'react';

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

class Productos extends Component {
  componentWillMount () {
    window.H5_loading.show();
    window.scrollTo(0, 0)
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  render () {
    return (
      <div>
        <div className="seccion sec_1 el_vh">
          <div className="sombra_sec el_vh">
            <div className="max_width">
              <center>
                <div className="flex_seccion el_vh">
                  <div>
                    <h12>PRODUCTOS</h12>
                    <br/>
                    Nuestra gran variedad de productos nos permite cumplir con las necesidades de cada uno de los sectores a los que abastecemos
                  </div>
                </div>
              </center>
            </div>
          </div>
        </div>

        <div className="bg_blanco">
          <div className="max_width padding_width_dos">
            {
              window._my_productos.length>0 ?
                window._my_productos.map((item, i) =>
                  <div key={i}>
                    {
                      i>0 ?
                        <div className="barra"></div>
                      :
                        null
                    }
                    <div className="flex_barra alinear">
                      <div className="img_barra" data-aos="fade-right">
                        <img src={item.imagen} className="img" alt={item.titulo} title={item.titulo}/>
                      </div>
                      <div className="texto_barra" data-aos="fade-left">
                        <h7>{item.titulo}</h7>
                        <div id="cl"><br/></div>
                        <div className="at_j ln_h">
                          {item.descripcion}
                        </div>
                        <div id="cl"><br/></div>
                        {
                          item.productos.length>0 ? 
                            item.productos.map((pro, n) => 
                              <div className="dotted-separator" key={n}>{pro.foto ? <a href={pro.foto} target="new">{pro.nombre}</a> : <h15>{pro.nombre}</h15>}</div>
                            )
                          :
                            null
                        }
                      </div>
                    </div>
                  </div>
                )
              :
                'No hay productos'
            }
          </div>
        </div>
      </div>
    )
  }
}

export default Productos;
