import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Element } from 'react-scroll';

class Menu extends Component {
  constructor() {
    super()
    this.state = {
      verMenu: 'ver_no',
      verIco: 'menu.svg',
    }
    this.verMenu = this.verMenu.bind(this)
  }

  activa(e) {
    this.verMenu()
  }

  verMenu() {
    if(this.state.verMenu==='ver_no'){
      this.setState({verMenu: 'ver_si', verIco: 'menu_x.svg'})
    }else{
      this.setState({verMenu: 'ver_no', verIco: 'menu.svg'})
    }
  }

  render () {
    return (
      <div>
        <Element name="inicio"></Element>
        <a href={window.whats} target="new" className="whatsapp"><img src="img/whats.svg" alt="Whatsapp" title="Whatsapp"/></a>
        <div className="menu_f">
          <div className="menu_back_f">
            <div className="max_width padding_men">
              <div className="menu_flex alinear">
                <Link to="inicio" onClick={this.activa.bind(this, 1)}><img src="img/logo_peran.png" className="logo_menu grises" alt="Distribuidora de carnes PERAN" title="Distribuidora de carnes PERAN"/></Link>
                <ul>
                  <li>
                    <Link to="inicio">Inicio</Link>
                  </li>
                  <li>
                    <Link to="productos">Productos</Link>
                  </li>
                  <li>
                    <Link to="contacto">Contacto</Link>
                  </li>
                </ul>
                <div><Link to="contacto" className="boton_contacto rojo">Contactar un asesor</Link></div>
              </div>
            </div>
          </div>
          <img src={"img/"+this.state.verIco} alt="Menú" title="Menú" className="menu_res" height="25" onClick={this.verMenu}/>
          <div className="cerrar_menu" onClick={this.verMenu} style={this.state.verMenu==='ver_si' ? {display: 'block'} : {display: 'none'}}></div>
          <div className="menu_r" id={this.state.verMenu}>
            <div className="overflow">
              <Link to="inicio" onClick={this.activa.bind(this, 1)}><img src="img/logo_peran.png" className="logo_menu2 m_bot grises" alt="Distribuidora de carnes PERAN" title="Distribuidora de carnes PERAN"/></Link>
              <ul>
                <li>
                  <Link to="inicio" onClick={this.activa.bind(this)}>Inicio</Link>
                </li>
                <li>
                  <Link to="productos" onClick={this.activa.bind(this)}>Productos</Link>
                </li>
                <li>
                  <Link to="contacto" onClick={this.activa.bind(this)}>Contacto</Link>
                </li>
              </ul>
              <div id="cl"><br/><br/></div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Menu