import React, { Component } from 'react';
//import { Link } from 'react-router-dom'

import Slider from './Slider'

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

class Inicio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      producto: 'Bovino',
    };
    this.verProductos = this.verProductos.bind(this);
  }

  componentWillMount () {
    window.H5_loading.show();
    //window.scrollTo(0, 0)
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  verProductos (e) {
    this.setState({
      producto: e,
    })
  }

  render () {
    return (
      <div>
        <Slider/>
        <div className="bg_blanco">
          <div className="max_width padding_width">
            <div className="pa_flex_2 alinear">
              <div className="de_flex_2" data-aos="fade-right">
                <h7>Conócenos</h7>
                <br/><br/>
                <h8>Distribuidora de Carnes Peran</h8>
                <br/>
                <h9>¡{window.experiencia} años de experiencia nos respaldan!</h9>
                <br/><br/>
                <div className="at_j ln_h">
                  Somos especialistas en venta de carne para carnicerías, restaurantes y negocios que requieran productos cárnicos de calidad en el estado de Hidalgo y área metropolitana.
                </div>
                <br/>
                <h9>Productos con garantías de calidad</h9>
                {/*
                <div id="cl"></div>
                <img src="img/logo_peran.png" className="tama_cono" alt="PERAN" title="PERAN"/>
                */}
              </div>
              <div className="de_flex_2" data-aos="fade-left">
                <img src="img/conocenos.png" className="img" alt="Conócenos" title="Conócenos"/>
              </div>
            </div>
          </div>
        </div>

        <div className="bg_negro">
          <div className="fondo_mitad img_right" data-aos="fade-right"></div>
          <div className="texto_mitad de_right">
            <div className="max_mitad de_left" data-aos="fade-left">
              <div className="padding_width_doble">
                <h77>¿Qué ofrecemos?</h77>
                <br/><br/>
                <div className="at_j ln_h">
                  La distribuidora de carnes Peran siempre estará a tu disposición. No sólo queremos venderte la mejor carne, también queremos venderte beneﬁcios, soluciones y tranquilidad. Nuestros Asesores te ayudarán, recomendarán y brindarán toda la información que necesites sobre:
                </div>
                <br/>
                <ul className="lista">
                  <li>Origen de productos</li>
                  <li>Procesos de producción</li>
                  <li>Piezas de carne</li>
                  <li>Tipos de corte</li>
                  <li>Rendimiento de la carne</li>
                  <li>Gramajes y porciones</li>
                  <li>Temperaturas y cadena de frío</li>
                  <li>Empaques y etiquetados</li>
                  <li>Entregas</li>
                </ul>
              </div>
            </div>
          </div>
          <div id="cl"></div>
        </div>

        <div className="bg_blanco">
          <div className="max_width padding_width">
            <div className="pa_flex_2 alinear">
              <div className="de_flex_2" data-aos="fade-right">
                <img src="img/beneficios.png" className="img" alt="Conócenos" title="Conócenos"/>
              </div>
              <div className="de_flex_2" data-aos="fade-left">
                <h7>Beneficios para tu negocio</h7>
                <br/><br/>
                <div className="at_j ln_h">
                  Nuestro compromiso es con los clientes. Garantizamos que la carne que ofrecemos es 100% natural y libre de inyección de agua, con lo que tendrás la seguridad de estar brindando a tus comensales productos de la mejor calidad, saludables y con el mejor sabor.
                  <br/><br/>
                  Además nuestros procesos cumplen con los protocolos más estrictos de higiene alimentaria y contamos con camionetas de entrega refrigeradas que preservan en todo momento la cadena de frío.
                  <br/><br/>
                  ¡No arriesgues la calidad de la carne que ofreces en tu negocio!
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="seccion sec_1">
          <div className="sombra_sec">
            <div className="max_width padding_width">
              <center>
                <div className="flex_seccion">
                  <div>
                    <h12>Nuestros Productos</h12>
                    <br/>
                    Nuestra gran variedad de productos nos permite cumplir con las necesidades de cada uno de los sectores a los que abastecemos
                  </div>
                </div>
              </center>
            </div>
          </div>
        </div>

        {
          window._my_productos.length>0 ?
            <div className="bg_gris">
              <div className="max_width padding_width">
                <div className="flex_productos">
                  <div className="lista_productos">
                    {
                      window._my_productos.map((item, i) =>
                        <div onClick={this.verProductos.bind(this, item.titulo)} key={i} className={this.state.producto===item.titulo ? 'activado' : null}>
                          {item.titulo}
                        </div>
                      )
                    }
                  </div>
                  <div className="img_productos">
                    {
                      window._my_productos.filter(p => p.titulo===this.state.producto).map((item, i) =>
                        item.productos.length>0 ? 
                          item.productos.map((pro, n) => 
                            pro.foto ? <img src={pro.foto} alt={pro.nombre} title={pro.nombre}/> : null
                          )
                        :
                          null
                      )
                    }
                    <div id="cl"></div>
                  </div>
                </div>
              </div>
            </div>
          :
            null
        }

        {/*
        <div className="bg_negro">
          <div className="texto_mitad">
            <div className="max_mitad de_right" data-aos="fade-right">
              <div className="padding_width_doble">
                <h7>¿Qué ofrecemos?</h7>
                <br/><br/>
                <div className="at_j ln_h">
                  La distribuidora de carnes Peran siempre estará a tu disposición. No sólo queremos venderte la mejor carne, también queremos venderte beneﬁcios, soluciones y tranquilidad. Nuestros Asesores te ayudarán, recomendarán y brindarán toda la información que necesites sobre:
                </div>
                <br/>
                <h9>Productos con garantías de calidad</h9>
              </div>
            </div>
          </div>
          <div className="fondo_mitad img_left" data-aos="fade-left"></div>
          <div id="cl"></div>
        </div>
        */}
      </div>
    )
  }
}

export default Inicio;
