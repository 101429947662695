import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

class Slider extends Component {
  componentWillMount () {
    window.H5_loading.show();
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  render () {
    return (
      <div>
        {
          window._my_slider.length > 0 ?
            <Carousel showArrows={true} showStatus={false} showIndicators={true} showThumbs={false} interval={5000} infiniteLoop={true} autoPlay>
              {
                window._my_slider.map((item, i) => 
                  <div className="carousel_img" key={i} style={{backgroundImage:`url('${item.imagen}')`}}>
                    <div className="sombra_car">
                      {/*<img src={item.imagen} alt={item.titulo}/>*/}
                      {
                        item.tipo === 3 ?
                          <div>
                            <img src="img/logo_peran.png" className="logo_car" alt="Distribuidora de carnes PERAN" title="Distribuidora de carnes PERAN"/>
                            <div className="quita">
                              <div id="cl"><br/></div>
                              <h122>DISTRIBUIDORA</h122>
                              <br/>
                              <h12>PERAN</h12>
                            </div>
                          </div>
                        :
                          item.descripcion || item.link ?
                            <div className="leyenda">
                              {
                                item.link ?
                                  item.tipo === 1 ?
                                    <Link to={item.link}>{item.descripcion ? item.descripcion : "Ver más"}</Link>
                                  :
                                    <a href={item.link} target="new">{item.descripcion ? item.descripcion : "Ver más"}</a>
                                :
                                  item.descripcion
                              }
                            </div>
                          :
                            null
                      }
                    </div>
                  </div>
                )
              }
            </Carousel>
          :
            <div className="fondo_inicial at_c"><div className="sombra_car"></div></div>
        }
      </div>
    )
  }
}

export default Slider;