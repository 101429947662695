import React, { Component } from 'react';
import { Link as Ir } from 'react-scroll';
import { Link } from 'react-router-dom'

const hoy = new Date(); 
const ano = hoy.getFullYear();

class Pie extends Component {
  render () {
    return (
      <div className="pie">
        <div className="max_width padding_pie">
          <div className="flex_pie alinear">
            <div className="pie_contacto">
              <a href={window.whats} target="new"><h10>{window.telefono}</h10></a>
              <br/>
              <a href={`mailto:${window.correo}`}>{window.correo}</a>
              <br/>
              <a href={`https://${window.web}`}>{window.web}</a>
              <br/><br/>
              <h11><a href={window.urlubi}>{window.ubicacion}</a></h11>
            </div>
            <div className="de_re_pie">
              <center><Link to="contacto" className="boton_contacto">Contactar un asesor</Link></center>
              <div id="cl"><br/><br/><br/></div>
              <center>Síguenos</center>
              <div className="flex_redes">
                {
                  window._my_redes.map((item, i) => 
                    <a href={item.link} target="_new" className="de_flex_redes" key={i}><img src={window.rutas+item.imagen} alt={item.nombre} title={item.nombre}/></a>
                  )
                }
              </div>
            </div>
            <div className="subi_pie">
              <center><Ir to="inicio" activeClass="active" spy={true} smooth={true} duration={500} title="Subir"><div className="subir">▲</div></Ir></center>
            </div>
          </div>

          <div className="derechos">
              Todos los derechos reservados © {ano}
          </div>
        </div>
      </div>
    )
  }
}

export default Pie;