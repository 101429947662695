import React, { Component } from 'react';
import Axios from 'axios'
import Swal from 'sweetalert2'

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

const alerta = (tipo,titulo) =>{
  Swal.fire({
  type: tipo,
  title: titulo
  });
}

class Contacto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre:'',
      correo:'',
      telefono:'',
      //asunto:'',
      mensaje:''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleChange(e) {
    const {name, value} = e.target;
    this.setState({[name]:value})
  }

  handleClick(e) {
    e.preventDefault();  
    const {nombre, correo, mensaje, telefono/*, asunto*/} = this.state;
    if(nombre===''){
      alerta('error','Ingresa tu nombre completo');
    }else if(correo===''){
      alerta('error','Ingresa tu correo electrónico');
    }else if(window.validarMail(correo)===false){
      alerta('error','Ingrese un correo electrónico valido');
    }else if(mensaje===''){
      alerta('error','Escribe un mensaje');
    }else{
      window.H5_loading.show();
      var params = new URLSearchParams();
      params.append('nombre', nombre);
      params.append('correo', correo);
      params.append('mensaje', mensaje);
      params.append('telefono', telefono);
      //params.append('asunto', asunto);
      
      Axios.post(`${window.envia}`, params)
        .then(response => {
          if(response.data.elError===1){
            alerta('success',response.data.mensaje)
            this.setState({nombre:'',correo:'',mensaje:'',telefono:'',/*asunto:''*/});
          }else{
            alerta('error',response.data.mensaje)
          }
        })
        .catch(error => {
          //window.alerta('error','LOLERO'+error);
          console.log(error)
        })
        .then(function() {
          window.H5_loading.hide();
        })
    }
  }

  componentWillMount () {
    window.H5_loading.show();
    window.scrollTo(0, 0)
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  render () {
    return (
      <div>
        <div className="seccion sec_2 el_vh">
          <div className="sombra_sec el_vh">
            <div className="max_width">
              <center>
                <div className="flex_seccion el_vh">
                  <div>
                    <h12>CONTACTO</h12>
                    <br/>
                    ¡Nuestros asesores responderán oportunamente todas tus dudas!
                  </div>
                </div>
              </center>
            </div>
          </div>
        </div>

        <div className="bg_blanco">
          <div className="max_width padding_width">
            <form id="formulario">
              <center><h14>Deja tu mensaje:</h14></center>
              <div id="cl"><br/></div>
              <div className="flex_inputs">
                <div>
                  Nombre:<br/>
                  <input type="text" name="nombre" onChange={this.handleChange} placeholder="Nombre completo:" value={this.state.nombre} required/>
                </div>
                <div>
                  Correo:<br/>
                  <input type="text" name="correo" onChange={this.handleChange} placeholder="Correo electrónico:" value={this.state.correo} required/>
                </div>
                <div>
                  Teléfono:<br/>
                  <input type="text" name="telefono" onChange={this.handleChange} placeholder="Número telefónico:" value={this.state.telefono}/>
                </div>
              </div>
              <div id="cl"><br/></div>
              Mensaje:<br/>
              <textarea name="mensaje" id="area" onChange={this.handleChange} placeholder="Escribe tu mensaje:" value={this.state.mensaje} required/>                  
              <center><button onClick={this.handleClick} className="button puntero">ENVIAR</button></center>
            </form>
          </div>
        </div>

        <div className="bg_negro">
          <div className="texto_mitad">
            <div className="max_mitad de_right" data-aos="fade-right">
              <div className="padding_width_doble infcontacto">
                <h13>Información de contacto</h13>
                <ul className="list1">
                  <li className="telefono"><a href={window.whats} target="new">{window.telefono}</a></li>
                  <li className="correo"><a href={`mailto:${window.correo}`}>{window.correo}</a></li>
                  <li className="ubicacion"><a href={window.urlubi}>{window.ubicacion}</a></li>
                </ul>
                <div id="cl"><br/><br/></div>
                Síguenos en redes sociales:
                <div className="redes_contacto">
                  {
                    window._my_redes.map((item, i) => 
                      <a href={item.link} target="_new" className="de_flex_contacto" key={i}><img src={item.imagen} alt={item.nombre} title={item.nombre}/></a>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
          <iframe src={window.mapa} className="mapa img_left" title="Mapa" allowfullscreen data-aos="fade-left"/>
          <div id="cl"></div>
        </div>
      </div>
    )
  }
}

export default Contacto;
