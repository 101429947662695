import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Inicio from './components/Inicio'
import Productos from './components/Productos'
import Contacto from './components/Contacto'
import Menu from './components/Menu'
import Pie from './components/Pie'

function App() {
  return (
    <Router>
      <Menu/>
      <Switch>
        <Route exact path="/" component={Inicio} />
        <Route path={window.path+"/inicio"} component={Inicio} />
        <Route path={window.path+"/productos"} component={Productos} />
        <Route path={window.path+"/contacto"} component={Contacto} />
        <Route component={Inicio} />
      </Switch>
      <Pie/>
    </Router>
  );
}

export default App;
